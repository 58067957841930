import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';


import Layout from "../components/layout"
import SEO from "../components/seo"
import FixedContainer from '../components/global/FixedContainer';
import SecondaryPageHeader from '../components/global/SecondaryPageHeader'
import CallToAction from '../components/global/CallToAction';
import CustomAccordion from '../components/global/Accordion';

const Wrapper = styled.div`
  ${tw`
    bg-gray-100 py-12
  `}
`;

const StyledH2 = styled.h2`
  border-bottom: 6px solid;
  ${tw`
    mb-8 font-display text-center inline-block font-black text-4xl border-lightBlue relative`
  }
  &::after{
    position:absolute;
    content: '';
    width: 100%;
    height:3px;
    background-color: #1e1f57;
    bottom:0;
    left: 0;
  }
`;

const Redactor = styled.div`
  & p{
    ${tw` mb-4 `}
  }
  & img{
    max-width:100%;
    height: auto;
  }

  & h2{
    ${tw`
      
    `}
  }

  & a{
    ${tw`
      text-lightBlue
      hover:text-darkBlue hover:underline
    `}
  }
`;

const CovidPage = () => {
  const data = useStaticQuery(graphql`
  query {
    covidPage: covidJson {
      data {
        richText
        title
        url
        image {
          alt
          url
        }
      }
    }
  }
`)

  return (
    <Layout>
      <SEO title="COVID-19 Resources" />
      {/* <SecondaryPageHeader heading={data.covidPage.title} image={data.covidPage.image.url} /> */}
      <Wrapper>
        <FixedContainer bg={true}>
          <StyledH2>Resources by Category</StyledH2>
          <Redactor
            dangerouslySetInnerHTML={{__html: data.covidPage.data[0].richText }} 
          />
          {/* <StyledH2>Wood County Resources</StyledH2> */}
          {/* <CustomAccordion items={data.covidPage.data[0].countyResources} /> */}
        </FixedContainer>
      </Wrapper>
      <CallToAction />
    </Layout>
  )
}

export default CovidPage
