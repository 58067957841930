import React from "react"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';

 const Wrapper = styled.section`
  ${tw`
    pt-12 pb-4 px-8 md:px-12 flex align-bottom items-end w-100
  `}
  height: 275px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 25% 65%;
  @media screen and (max-width: 512px){
    height: 175px;
  }
 `;


 const StyledH2 = styled.h2`
  ${tw`
    text-3xl
    font-black relative text-white
    md:text-5xl
  `}
 `;


const SecondaryPageHeader = ({heading, image, children}) => {
  const bg = {
    background: `radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 4%, rgba(30,31,87,0.65) 100%), url(${image}) no-repeat 25% 65% scroll`,
  }
  return (
    <Wrapper style={bg}>
        <div className={"container mx-auto px-8 md:px-12 "}>
          <StyledH2>{heading}</StyledH2>
          {children}
        </div>
    </Wrapper>
  )
}

export default SecondaryPageHeader
